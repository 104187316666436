export default {
   params: {
      transType0: "Actual commodities",
      transType1: "Contract",
      directionType0: "Buy ",
      directionType1: "Sell",
      lockStateType0: "Toward",
      lockStateType1: "Closed",
      lockStateType2: "Cancelled",
   },
   common: {
      cardMain: "Front Side",
      cardBack: "Back",
      amount: "Amount",
      voucher: "Recharge Voucher",
      withdraw1: "Withdrawal account",
      withdraw2: "Select Type",
      remark: "Remark",
      verify:"Accreditation",
      verifySuccess:"Certified",
      onlineService: "client service",
      tip1: "Please enter the correct amount",
      tip2: "Insufficient account balance",
      confirm: "Confirmation",
      cancel: "Cancel",
      tip: "Tip",
      register: "Register",
      viewMore: "View More",
      trade: "Trade",
      empty: "No content",
      searchPairs: "Search Pairs",
      and: "And",
      next: "Next step",
      success: "Successes",
      more: "More",
      main: "MMain picture",
      sub: "Picture",
      copySuccess: "Copy Success！",
      copyError: "Copy Failure！",
      view: "View",
      all: "Full",
      goSet: "Settings",
      available: "Usability",
      confirmCancel: "Are you sure you want to cancel?？",
      copy: "Clone",
      submit: "Submit",
      send: "Sent",
      keep: "Continue placing orders",
      revenue: "Expected revenue",
   },
   mine: {
      lastTime: "Last login",
   },
   head: {
      mineInvite: "Invite Friends",
      mineAddress: "Withdrawal address",
      mine: "Account",
      orderCoin: "Spot Order",
      orderContract: "Contract Orders",
      orderSecond: "Option Orders",
      order: "Orders",
      assetsWithdraw: "Withdraw",
      assetsRecharge: "Deposit",
      assetsTotal: "Total assets",
      assets: "Asset",
      logout: "Logout",
      menuTit1: "Market price",
      menuTit2: "Deal",
      menuTit3: "Finance",
      menuTit4: "New Currency",
      menuTit5: "Contest",
      menuTit6: "Event",
      menuTit7: "Blogger",
      menuTit8: "Discover",
      menuItem0: "Options Trading",
      menuItem1: "Coin Trading",
      menuItem2: "Contract trading",
      menuItem3: "Launchpad",
      menuItem4: "Vote on Coin",
      menuItem5: "Livestream",
      menuItem6: "Offline Event",
      login: "Login",
      register: "Register",
      download: "Download",
      noPermissions: "Your IP address shows you are in a country where ICESAS's services are restricted.",
   },
   home: {
      h1: "The First Choice For The Initial Launch",
      p1: "Trade over 50 cryptocurrencies on ICESAS",
      tradeTab1: "Coin Trading",
      tradeTab2: "Contractual Transactions",
      tradeTag1: "Rise and fall of stock in one currency",
      tradeTag2: "Hot Coins",
      tradeTag3: "Prime",
      serviceTit: "A wide range of services to choose from",
      serviceTit1: "Spot Trading",
      servicedesc1: "Buying, selling or trading cryptocurrencies at prevailing market prices.",
      serviceTit2: "Permanent Contract",
      servicedesc2: "Trading contracts by going long or short",
      serviceTit3: "Finance",
      servicedesc3: "A pledge that increases revenue day by day.",
      serviceTit4: "Prime",
      servicedesc4: "Certify quality new tokens",
      liveTit: "ICESAS Live Streaming",
      whyTit: "ICESAS Live Streaming",
      whyTit1: "Safe and trustworthy",
      whySubtit1: "Real-time internal clearing, auditing and risk management Separation of cold wallets and multi-signature wallets to safeguard funds Professional third-party security organization auditing, Professional third-party security organization auditing",
      whyLink: "View All Security Mechanisms",
      whyTit2: "Powerful deal aggregation engine",
      whySubtit2: "Ultra-low latency summarization engine, massive throughput, no jitter at extreme peaks, no downtime at peaks",
      whyTit3: "Trusted Platform",
      whySubtit3: "As one of the top 50 trading platforms in the world, ICESAS strictly follows the rules of the industry and does not participate in market operations.",
      whyTit4: "Fast trading system",
      whySubtit4: "ICESAS provides you with a simple and clear trading interface and convenient operation process, making it more convenient for you to trade digital assets.",
      guideTit: "Beginner's Guide",
      guideh1: "Create a ICESAS Account",
      guidep1: "Register now and start your trading journey!",
      guideBtn1: "Register",
      guideh2: "Depositor",
      guidep2: "Quick recharge and easy crediting!",
      guideBtn2: "Coin-charging",
      guideh3: "Start trading",
      guidep3: "Start trading and enjoy unlimited possibilities!",
      guideBtn3: "Coin Trading",
      readyTop1: "Ready to start?？",
      readyMid: "ICESAS has advanced technology to provide you with a fast buying, selling and trading experience. Trade securely with our simple platform built just for you.",
      readyTit: "Scan to download APP",
      readySubtit: "Android and iOS supported",
      readyDownload: "Download on the",
      readyAppStore: "AppStore",
      readyGetin: "GET IT ON",
      readyGoole: "Google Play",
      faqTit: "FAQ",
      faqTit1: "Funding Experience",
      faqCont1: "Futures Credit is a digital money lending bonus for ICESAS users to help them understand ICESAS perpetual contract trading. Compared to ICESAS's traditional futures trading, combined with the principal amount of crypto assets provided by the user, Futures Credit can be used as a relatively large opening margin for Futures Credit trading.",
      faqTit2: "What is CoinBox?",
      faqCont2: "Futures CoinBox is a new product feature from ICESAS that allows you to send a cryptocurrency CoinBox to a friend.You can create a cryptocurrency CoinBox and share it with your friends. When your friend enters the CoinBox password through the ICESAS app to receive the gift, they will receive a random amount of cryptocurrency from the CoinBox.Credit can be used as a relatively large margin for opening positions in Futures Credit trading.",
      faqTit3: "Beginner's Guide to Making Money",
      faqCont3: "Futures ICESAS's latest cryptocurrency wealth management product is crafted by ICESAS's quantitative team, consisting of experienced quantitative investment fund managers, financial elites from the futures and securities industry, and BAT technologists. The team was established in Singapore in 2021 and focuses on quantitative management of digital assets with specialized quantitative modeling solutions and management capabilities.Credit can be used as a relatively large opening margin in Futures Credit trading.",
      faqTit4: "Join us live on ICESAS",
      faqCont4: "Futures Live is a live streaming product featuring top memory blockchain projects and industry experts delivering the latest and hottest talks and presentations. Join us to explore more exciting content.Credit can be used as a relatively large opening margin in Futures Credit trading.",
      faqTit5: "How to buy cryptocurrency at ICESAS",
      faqCont5: "Futures ICESAS offers several third-party cryptocurrency buying service providers to users worldwide. See our article on cryptocurrency buying services.Credit can be used as relatively large opening margin for Futures Credit trades.",
      partnerTit: "Cooperative partner",
   },
   assets: {
      tit: "Total assets",
      recharge: "Deposit",
      withdraw: "Withdraw",
      transfer: "Transfer",
      tab1: "Total assets",
      tab2: "Coin Account",
      tab3: "Contract account",
      tab4: "contractual quantitative account (CQA)",
      tab5: "Financial Accounts",
      assetsTotal: "Come",
      assetsReal: "Usability",
      assetsFrozen: "Frozen",
      proportion: "Percentage of assets",
      tip1: "Minimum Withdrawal",
      tip2: "Withdrawals below the minimum amount will not be credited and cannot be refunded",
      tip3: "Minimum Withdrawal",
      linkType: "Chain type",
      rechargeAddress: "Filling address",
      contractAddress: "Contract address",
      rechargeRecord: "Coin Filling Record",
      tip4: "You have not completed the password setting, do not support charging coins for the time being.",
      withrawAddress: "Withdrawal address",
      placeWithrawAddress: "Please enter the withdrawal address",
      withrawAmount: "Withdrawal amount",
      placeWithrawAmount: "Please enter the withdrawal amount",
      availableLimit: "Available credit",
      fee: "Service charge",
      expectedReceipt: "Projected arrival",
      withdrawRecord: "Coin withdrawal records",
   },
   login: {
      login: "Login",
      register: "Register",
      email: "Email",
      phone: "Phones",
      forget: "Forgotten password?？",
      noAccount: "No account？",
      haveAccount: "Already have account?",
      agree1: "I understand.",
      agree2: "User agreement",
      agree3: "Privacy agreement",
      resetPassword: "Reset Password",
      securityTip: "In order to ensure the security of your assets, you can not withdraw coins 24 hours after the password change",
   },
   form: {
      send: "Send Code",
      labelCaptcha: "Verification Code",
      errorCaptcha: "Please Enter the verification code",
      labelEmail: "Email",
      errorEmail: "Please enter Email",
      placeEmail: "Please enter Email",
      labelPhone: "Phone",
      errorPhone: "Please enter the correct cell phone number!",
      placePhone: "Please enter your cell phone number",
      labelPassword: "Password",
      errorPassword: "Please enter your login password",
      placePassword: "Please enter your login password",
      labelInvite: " Invitation Code (optional)",
      errorInvite: "Please enter the invitation code",
      placeInvite: "Please enter the invitation code",
      placeSelece: "Please choose",
      account_number: "Account Number",
      label_type: "Select Type",
      place_type: "Please select type",
      label_number: "Account Number",
      place_number: "Account Number",
      wallet_address: 'Wallet Address',
      bank_name: 'Bank name',
      bank_address: "Bank Address",
      name: "Name",
      placeName: "Please enter a name",
      payee_address: "Payee's address",
      swift_code: "Swift/Swift Code/BIC",
      aba: "ABA",
      branch_number: "Branch Name",
      branch_code: "Line code",
      bank_code: "Bank Code",
      phone: "Cell phone number",
      iban: "IBAN",
      sort_code: "Sort Code",
      placeInner: "Please enter content",
      labelCurrency: "Currency type",
      placeCurrency: "Please select currency",
      labelNumber: "Quantities",
      placeNumber: "Please enter the quantity",
      messageNumber: "Please enter the quantity",
      labelPrice: "prices",
      placePrice: "Please enter a price",
      placeAmount: "Please enter the amount",
      labelJoinMoney: "Amount of accession",
      labelOldPassword: "Old password",
      placeOldPassword: "Please enter the old password",
      messageOldPassword: "Please enter the old password",
      psdTip: "Password must be a combination of 6 ~ 16 bit letters + digits",
      labelNewPassword: "New password",
      placeNewPassword: "Please enter a new password",
      messageNewPassword: "Please enter a new password",
      labelCrmPassword: "Confirm Password",
      placeCrmPassword: "Please confirm your login password",
      messageCrmPassword: "Please confirm your login password",
      labelCountry: "Country/Region",
      placeCountry: "Please select a country",
      messageCountry: "Please select a country",
      labelId: "ID number",
      messageId: "Please enter ID number",
      labelCaptchaEmail: "The verification code will be sent to",
      labelTotalAmount: "Total",
   },
   table: {
      labelName: "Name (of a thing)",
      labelLastPrice: "Latest price",
      labelRang: "24H Up and down",
      labelVolume: "24H Turnover",
      labelVolumeNum: "24H Volume",
      labelKline: "K-line chart",
      labelHeight: "24H High",
      labelLow: "24H Low",
      labelOperate: "Manipulate",
      labelTime: "Timing",
      labelCurrency: "Currency type",
      labelAmount: "Monetary value",
      labelState: "State of affairs",
      state0: "Done",
      state1: "Failures",
      state2: "Cancelled",
      withdrawState0: "Awaiting approval",
      withdrawState1: "Done",
      withdrawState2: "Rejected",
      labelDirection: "Directional",
      labelPrice: "Prices",
      labelLever: "Pry bar",
      labelHangNum: "handful (of)",
      gmtCreate: "Creation time",
      operate: "Manipulate",
      labelPeriod: "Settlement cycle",
      every: "Each",
      hour: "Hourly",
      day: "Day",
      labelFundRate: "Funding rate",
      labelDailyRate: "Same-day funding rate",
      labelLevesCurrency: "Investment Currency",
      labelReturn: "Annualized rate of return",
      labelLevesTerm: "Investment Term",
      labelStartAmount: "Starting amount",
      viewDetails: "View Details",
      labelPairs: "Trade pair",
      labelType: "Typology",
      labelEntrustNumber: "Number of commissions",
      labelTransNumber: "Number of transactions",
      labelTotalAmount: "Total amount traded",
      labelEntrustTime: "Commissioning time",
      labelBuyPrice: "Buy-in price",
      labelBuyNumber: "Buy-in quantity",
      labelBuyTime: "Time of purchase",
      labelSellPrice: "Asking price",
      labelProfitLoss: "Waxing and waning",
      labelNumber: "Quantities",
      labelMinerTitle: "Financial Name",
   },
   foot: {
      menuTit1: "About Us",
      menuTit2: "Contact Us",
      menuTit3: "Resource",
      menuTit4: "Help",
      menuTit5: "Dealings",
      menuItem1: "ICESAS Team",
      menuItem2: "Authentication channel",
      menuItem3: "Business Collections Authorization",
      menuItem4: "Associate",
      menuItem5: "About ICESAS",
      menuItem6: "User agreement",
      menuItem7: "Official Email",
      menuItem8: "ICESAS Contracts",
      menuItem9: "Coin application",
      menuItem10: "Market price",
      menuItem11: "ICESAS Academy",
      menuItem12: "Standard rates",
      menuItem13: "Withdrawal Fee & Withdrawal Limit",
      menuItem14: "Currency Description",
      menuItem15: "Surety",
      menuItem16: "Filling & Withdrawing Status",
      menuItem17: "Suggestions and feedback",
      menuItem18: "Help Center",
      menuItem19: "Work Order Submission",
      menuItem20: "API documentation",
   },
   market: {
      spots: {
         filterTab1: "Self-service",
         filterTab2: "Coin Quotes",
         filterTab3: "Contract market",
         filterTab4: "Launchpad",
         tabAll: "Full",
         tab1: "Main area",
         tab2: "Blaze new trails",
         tab3: "Open area",
         tab4: "Prime",
         tab5: "Coins",
         tab6: "Contractor",
         tab7: "USDT Forever",
         tab8: "Full Currency Perpetual",
      }
   },
   trade:{
      chartMsg: "Currency Information",
      marketPrice:"Market value",
      limitPrice:"Limit the price",
      marketPriceTip: "Market orders are bought and sold using the best current market price.",
      lever: "Pry bar",
      handNum: "Handful (of)",
      margin: "Cash deposit",
      assets:"Capital",
      balance:"Balances",
      book: "Commission book",
      quantity: "Cumulative volume",
      navState0: "Waiting to open a position",
      navState1: "Current Position",
      navState2: "Historical commissions",
      navState3: "Current commissions",

      fund: {
         tit1: "Contract market",
         tit2: "Funding rate history",
         p1: "An insurance fund to prevent investors' positions from being automatically reduced. The fund is used to improve the price of unexecuted closed mandates to avoid them being taken over by the automatic position reduction system.",
         p2: "The increase in the amount of insurance funds comes from strong flat mandates that are traded in the market at a price better than the bankruptcy price.",
         p3: "The current and historical balances of the insurance funds can be viewed below.",
         tab1: "USDT Forever",
         tab2: "Full Currency Perpetual",
         contract: "Contractor",
      },
   },
   earn: {
      tit: "One-click pledge, increasing income day by day",
      tab1: "Fixed-term finance",
      orderTit: "Financial Orders",
      labelTotalApr: "Annualized rate of return",
      labelTimeLimit: "Project cycle",
      labelMoney: "Amount of accession",
      labelType: "Payback method",
      labelType1: "Debt service at maturity",
      labelNowBalance: "Current balance",
   },
   invite: {
      tit: "Invite Friends",
      p: "Scan this QR code to invite friends",
      iviteLink: "Invitation Links",
      registerCode: "Registration code",
   },
   noPermissions: {
      tip: "Sorry, you are in the area of the temporary Jiao law defense week this program station, the black law to provide services to move",
   },
   order: {
      tit: "Order Record",
      tab1: "Options orders",
      tab2: "Coin Orders",
      tab3: "Contract order",
      tab4: "Financial Orders",
      filterType0: "All types",
      filterType1: "Done",
      filterType2: "Cancelled",
      filterType3: "Current Position",
      filterDirection0: "Full Direction",
      filterDirection1: "Buy-in",
      filterDirection2: "reach (a price in an auction) stubbornly",
   },
   personal: {
      tit1: "Security level",
      tit2: "Login password",
      tit3: "Trading Password",
      tit4: "Security Settings",
      strong: "Weak",
      setting: "Change",
      settings: "Settings",
      change: "Variation",
      idTip1: "Authenticate",
      idTip2: "Authenticate yourself before proceeding with the rest of the operation",
      withdrawTip1: "Withdrawal address",
      withdrawTip2: "Please bind the withdrawal address",
      loginPsdTit: "Setting the login password",
      withdrawPsdTit: "Setting a password for withdrawals",
      idTit: "Identity Verification",
      phoneTit: "Cell Phone Binding",
      phoneTip: "Cancelation is prohibited for 24 hours after cell phone replacement",
      walletTit: "Binding Wallet",
      accountNumber: "Account Number",
      addAddress: "Add Address",

   },
   contest: {
      hot: "Hot Events",
   },
   city: {
      albania: "Albania",
      algeria: "Algeria",
      argentina: "Argentina",
      armenia: "Armenia",
      australia: "Australia",
      pakistan: "Pakistan",
      austria: "Austria",
      bahrain: "Bahrain",
      belgium: "Belgium",
      bosnia_and_Herzegovina: "Bosnia and Herzegovina",
      brazil: "Brazil",
      brunei: "Brunei",
      bulgaria: "Bulgaria",
      cambodia: "Cambodia",
      canada: "Canada",
      cameroon: "Cameroon",
      chile: "Chile",
      colombia: "Colombia",
      costa_Rica: "Costa Rica",
      croatia: "Croatia",
      cyprus: "Cyprus",
      czech_Republic: "Czech Republic",
      denmark: "Denmark",
      dominican_Republic: "Dominican Republic",
      egypt: "Egypt",
      estonia: "Estonia",
      ethiopia: "Ethiopia",
      finland: "Finland",
      france: "France",
      georgia: "Georgia",
      germany: "Germany",
      ghana: "Ghana",
      greece: "Greece",
      guyana: "Guyana",
      honduras: "Honduras",
      hong_Kong_China: "Hong Kong, China",
      hungary: "Hungary",
      iceland: "Iceland",
      ireland: "Ireland",
      italy: "Italy",
      india: "India",
      indonesia: "Indonesia",
      israel: "Israel",
      iran: "Iran",
      iraq: "Iraq",
      japan: "Japan",
      kazakstan: "Kazakhstan",
      kenya: "Kenya",
      korea: "Korea",
      kuwait: "Kuwait",
      kyrgyzstan: "Kyrgyzstan",
      laos: "Laos",
      latvia: "Latvia",
      lithuania: "Lithuania",
      luxembourg: "Luxembourg",
      macao_China: "Macao_China",
      macedonia: "Macedonia",
      malaysia: "Malaysia",
      malta: "Malta",
      mexico: "Mexico",
      moldova: "Moldova",
      monaco: "Monaco",
      mongolia: "Mongolia",
      montenegro: "Montenegro",
      morocco: "Morocco",
      myanmar: "Myanmar",
      netherlands: "Netherlands",
      new_Zealand: " New_Zealand",
      nepal: "Nepal",
      nigeria: "Nigeria",
      norway: "Norse",
      oman: "Oman",
      palestine: "Palestine",
      panama: "Panama",
      paraguay: "Paraguay",
      peru: "Peru",
      philippines: "Philippines",
      poland: "Poland",
      portugal: "Portugal",
      puerto_Rico: "Puerto Rico",
      qatar: "Qatar",
      romania: "Romania",
      russia: "Russia",
      republic_of_Trinidad_and_Tobago: "Republic of Trinidad and Tobago",
      rwanda: "Rwanda",
      saudi_Arabia: "Saudi Arabia",
      serbia: "Serbia",
      singapore: "Singapore",
      slovakia: "Slovakia",
      slovenia: "Slovenia",
      south_Africa: "South Africa",
      spain: "Spain",
      sri_Lanka: "Sri Lanka",
      sweden: "Sweden",
      switzerland: "Switzerland",
      taiwan_China: "Taiwan_China",
      tajikistan: "Tajikistan",
      tanzania: "Tanzania",
      thailand: "Thailand",
      turkey: "Turkey",
      turkmenistan: "Turkmenistan",
      ukraine: "Ukraine",
      united_Arab_Emirates: "United Arab Emirates",
      united_Kingdom: "United Kingdom",
      united_States: " United_States",
      uzbekistan: "Uzbekistan",
      venezuela: "Venezuela",
      vietnam: "Vietnam",
      afghanistan: "Afghanistan",
      angola: "Angola",
      azerbaijan: "Azerbaijan",
      bangladesh: "Bangladesh",
      belarus: "Belarus",
      belize: "Belize",
      benin: "Benin",
      bhutan: "Bhutan",
      bolivia: "Bolivia",
      botswana: "Botswana",
      british_Virgin_Islands: "British Virgin Islands",
      burkina_Faso: "Burkina Faso",
      burundi: "Burundi",
      cape_Verde: "Cape Verde",
      cayman_Islands: "Cayman Islands",
      central_African_Republic: "Central African Republic",
      chad: "Chad",
      comoros: "Comoros",
      the_Republic_of_the_Congo: " The_Republic_of_the_Congo",
      democratic_Republic_of_the_Congo: "Democratic Republic of the Congo",
      djibouti: "Djibouti",
      ecuador: "Ecuador",
      el_Salvador: "El Salvador",
      equatorial_Guinea: "Equatorial Guinea",
      eritrea: "Eritrea",
      fiji: "Fiji",
      gabon: "Gabon",
      gambia: "Gambia",
      greenland: "Greenland",
      guatemala: "Guatemala",
      guinea: "Guinea",
      haiti: "Haiti",
      isle_of_Man: " Isle_of_Man",
      cote_d_Ivoire: "Cote_d_Ivoir",
      jamaica: "Jamaica",
      jordan: "Jordan",
      lebanon: "Lebanon",
      lesotho: "Lesotho",
      liberia: "Liberia",
      libya: "Libya",
      madagascar: "Madagascar",
      malawi: "Malawi",
      maldives: "Maldives",
      mali: "Mali",
      mauritania: "Mauritania",
      mauritius: "Mauritius",
      mozambique: "Mozambique",
      namibia: "Namibia",
      nicaragua: "Nicaragua",
      republic_of_Niger: "Republic_of_Niger",
      north_Korea: " North_Korea",
      reunion: "Reunion",
      san_Marino: "SAN Marino",
      senegal: "Senegal",
      sierra_Leone: "Sierra Leone",
      somalia: "Somalia",
      sudan: "Sudan",
      suriname: "Suriname",
      eswatini: "Eswatini",
      syria: "Syria",
      togo: "Togo",
      tonga: "Tonga",
      tunisia: "Tunisia",
      united_States_Virgin_Islands: "United States Virgin Islands",
      uganda: "Uganda",
      uruguay: "Uruguay",
      vatican: "Vatican",
      yemen: "Yemen",
      yugoslavia: "Yugoslavia",
      zambia: "Zambia",
      zimbabwe: "Zimbabwe",
      china: "China",
   }
};






