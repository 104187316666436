export default {
   params: {
      transType0: "在庫商品",
      transType1: "契約",
      directionType0: "買い付け",
      directionType1: "付く",
      lockStateType0: "ランニング",
      lockStateType1: "クローズド",
      lockStateType2: "キャンセル",
   },
   common: {
      cardMain: "Front Side",
      cardBack: "Back",
      amount: "金額",
      voucher: "チャージ証明書",
      withdraw1: "現金引き出し口座",
      withdraw2: "タイプの選択",
      remark: "コメント",
      verify:"認定",
      verifySuccess:"認定合格",
      onlineService: "顧客サービス",
      tip1: "正しい金額を入力してください。",
      tip2: "口座残高の不足",
      confirm: "定義する",
      cancel: "キャンセル",
      tip: "注意を引く",
      register: "在籍",
      viewMore: "もっと見る",
      trade: "取引",
      empty: "現時点ではコンテンツなし",
      searchPairs: "通貨ペア検索",
      and: "歌で応える",
      next: "次のステップ",
      success: "成功",
      more: "もっと見る",
      main: "メイン写真",
      sub: "しんぷく図",
      copySuccess: "コピー成功！",
      copyError: "コピーに失敗しました！",
      view: "表示＃ヒョウジ＃",
      all: "すべて",
      goSet: "デセット",
      available: "使用可能",
      confirmCancel: "本当にキャンセルしますか？？",
      copy: "レプリケーション",
      submit: "送信",
      send: "送信",
      keep: "注文を続行",
      revenue: "予想収益",
   },
   mine: {
      lastTime: "前回のログイン",
   },
   head: {
      mineInvite: "友達を招待する",
      mineAddress: "出金先",
      mine: "アカウントセンター",
      orderCoin: "現物注文",
      orderContract: "契約オーダ",
      orderSecond: "オプション注文",
      order: "オーダー",
      assetsWithdraw: "貨幣をつりあげる",
      assetsRecharge: "貨幣を充填する",
      assetsTotal: "総資産換算",
      assets: "資産",
      logout: "ログアウト",
      menuTit1: "相場",
      menuTit2: "取り引き",
      menuTit3: "資産管理",
      menuTit4: "新規通貨のオンライン",
      menuTit5: "Contest",
      menuTit6: "Event",
      menuTit7: "ブログ",
      menuTit8: "Discover",
      menuItem0: "オプション取引",
      menuItem1: "貨幣取引",
      menuItem2: "契約取引",
      menuItem3: "Launchpad",
      menuItem4: "投票で貨幣を供出する",
      menuItem5: "Livestream",
      menuItem6: "Offline Event",
      login: "ログイン＃ログイン＃",
      register: "登録",
      download: "スキャンコードのダウンロード",
      noPermissions: "IPアドレスには、ICESASサービスの制限がある国が表示されます。",
   },
   home: {
      h1: "The First Choice For The Initial Launch",
      p1: "Trade over 50 cryptocurrencies on ICESAS",
      tradeTab1: "コイントレーディング",
      tradeTab2: "契約取引",
      tradeTag1: "一日の株の上げ下げ",
      tradeTag2: "ホットコイン",
      tradeTag3: "Prime",
      serviceTit: "選べる幅広いサービス",
      serviceTit1: "スポット取引",
      servicedesc1: "現在の市場価格で暗号通貨を売買すること。",
      serviceTit2: "永久契約",
      servicedesc2: "ロングまたはショートによる契約の取引",
      serviceTit3: "財務管理",
      servicedesc3: "ワンクリックで日収アップの誓約書",
      serviceTit4: "Prime",
      servicedesc4: "高品質な新トークンを購読する",
      liveTit: "ICESASライブ",
      whyTit: "ICESASライブ",
      whyTit1: "安全で信頼できる",
      whySubtit1: "リアルタイムの内部清算、監査とリスク管理冷財布と多重署名財布を分離し、資金安全専門の第三者安全機構監査、専門の第三者安全機構監査を保障する",
      whyLink: "すべてのセキュリティメカニズムの表示",
      whyTit2: "強力な取引仲介エンジン",
      whySubtit2: "超低遅延のつまみエンジン、膨大なスループット、ピークはブレず、ピークはダウンタイムしない",
      whyTit3: "信頼性の高いプラットフォーム",
      whySubtit3: "世界トップ50の取引プラットフォームの1つとして、ICESASは業界のルールに厳格に従い、市場運営に参加していません。",
      whyTit4: "迅速な取引システム",
      whySubtit4: "ICESASは、デジタル資産取引を容易にするための簡潔で明瞭な取引インタフェースと簡単な操作プロセスを提供します。",
      guideTit: "初心者向けガイド",
      guideh1: "ICESASアカウントを作成する",
      guidep1: "今すぐ登録して、取引の旅を始めましょう！",
      guideBtn1: "登録",
      guideh2: "預金",
      guidep2: "高速チャージで簡単に入金！",
      guideBtn2: "貨幣を充填する",
      guideh3: "取引開始",
      guidep3: "取引を開始して取引を開始して、無限の可能性を享受します！",
      guideBtn3: "貨幣取引",
      readyTop1: "準備は始まったか？",
      readyMid: "ICESASには高度なテクノロジーがあり、迅速な取引や取引体験を提供しています。お客様専用のシンプルなプラットフォームを使用して、安全に取引を行うことができます。",
      readyTit: "Scan to download APP",
      readySubtit: "Android and iOS supported",
      readyDownload: "Download on the",
      readyAppStore: "AppStore",
      readyGetin: "GET IT ON",
      readyGoole: "Google Play",
      faqTit: "FAQ",
      faqTit1: "配資体験金",
      faqCont1: "Futures Creditは、ICESASユーザーに提供されるデジタル資金貸借奨励金であり、ICESAS永続契約取引を理解するのに役立ちます。ICESASの従来の先物取引と比較して、Futures Creditは、ユーザーが提供する暗号化資産元本と合わせて、Futures Credit取引の中で比較的大きなオープン保証金とすることができる。",
      faqTit2: "CoinBoxとは？",
      faqCont2: "Futures CoinBoxは、友人に暗号化通貨CoinBoxを送信できるICESASの新製品機能です。暗号化された通貨CoinBoxを作成し、友人と共有することができます。友人がICESASアプリケーションを通じてCoinBoxパスワードを入力してプレゼントを受け取ると、CoinBoxからランダムな数の暗号化された通貨が受信されます。Creditは、Futures Credit取引における比較的大きなオープンマージンとすることができる。",
      faqTit3: "初心者のためのマネーガイド",
      faqCont3: "Futures ICESASの最新の暗号化通貨フォーチュン管理製品は、経験豊富な量子化投資ファンドマネージャ、先物や証券業界の金融エリート、BAT技術の専門家で構成されるICESAS量子化チームによって丹念に構築されています。このチームは2021年にシンガポールで設立され、デジタル資産の量子化管理に専念し、専門的な量子化モデル案と管理能力を持っている。Creditは、Futures Credit取引における比較的大きなオープンマージンとすることができる。",
      faqTit4: "私たちのICESASライブに参加します",
      faqCont4: "Futures Liveは、最上位のメモリブロックチェーンプロジェクトと業界の専門家を招いて最新および最上位の講座と講演を届けるライブ配信製品です。私たちに参加して、より多くの素晴らしい属性を探索します。Creditは、Futures Credit取引における比較的大きなオープンマージンとすることができる。",
      faqTit5: "ICESASで暗号化通貨を購入する方法",
      faqCont5: "Futures ICESASは、世界中のユーザーに複数のサードパーティ暗号化通貨購入サービスプロバイダを提供しています。暗号化通貨購入サービスに関する記事をご覧ください。Creditは、Futures Credit取引における比較的大きなオープンマージンとすることができる。",
      partnerTit: "パートナー",
   },
   assets: {
      tit: "総資産",
      recharge: "貨幣を充填する",
      withdraw: "貨幣をつりあげる",
      transfer: "の方向をそらす",
      tab1: "資産総額",
      tab2: "貨幣口座",
      tab3: "契約アカウント",
      tab4: "契約量子化アカウント",
      tab5: "資産運用勘定科目",
      assetsTotal: "合計＃ゴウケイ＃",
      assetsReal: "使用可能",
      assetsFrozen: "とうけつ",
      proportion: "資産比率",
      tip1: "最小引き出し金額",
      tip2: "最低金額未満の現金引き出しは入金されず、返金できません",
      tip3: "最小引き出し金額",
      linkType: "チェーンタイプ",
      rechargeAddress: "チャージアドレス",
      contractAddress: "契約アドレス",
      rechargeRecord: "チャージ記録",
      tip4: "パスワード設定を完了していないため、チャージをサポートしていません",
      withrawAddress: "キャッシュアドレス",
      placeWithrawAddress: "現金引き出し先を入力してください",
      withrawAmount: "引き出し金額",
      placeWithrawAmount: "現金引き出し金額を入力してください",
      availableLimit: "使用可能な限度",
      fee: "手数料",
      expectedReceipt: "予定入金",
      withdrawRecord: "貨幣引出記録",
   },
   login: {
      login: "ログイン＃ログイン＃",
      register: "登録",
      email: "メールボックス",
      phone: "携帯電話",
      forget: "パスワードを忘れる？",
      noAccount: "アカウントがありません？",
      haveAccount: "既存のアカウント？",
      agree1: "私は理解しています",
      agree2: "ユーザプロトコル",
      agree3: "プライバシー契約",
      resetPassword: "パスワードをリセット",
      securityTip: "あなたの資産の安全を保証するために、パスワードの変更後24時間は貨幣を引き出すことができません",
   },
   form: {
      send: "認証コードの送信",
      labelCaptcha: "認証コード",
      errorCaptcha: "認証コードを入力してください",
      labelEmail: "メールボックス",
      errorEmail: "正しいメールアドレスを入力してください",
      placeEmail: "メールアドレスを入力してください",
      labelPhone: "携帯電話",
      errorPhone: "正しい携帯番号を入力してください",
      placePhone: "携帯番号を入力してください",
      labelPassword: "パスワード",
      errorPassword: "正しいパスワードを入力してください",
      placePassword: "パスワードを入力してください",
      labelInvite: "招待コード（選択）",
      errorInvite: "招待コードを入力してください",
      placeInvite: "招待コードを入力してください",
      placeSelece: "選択してください",
      account_number: "勘定科目番号",
      label_type: "タイプの選択",
      place_type: "タイプを選択してください",
      label_number: "勘定科目番号",
      place_number: "勘定科目番号",
      wallet_address: 'ウォレットアドレス',
      bank_name: '銀行名',
      bank_address: "銀行住所",
      name: "名前",
      placeName: "名前を入力してください",
      payee_address: '受取人住所',
      swift_code: "Swift/Swift Code/BIC",
      aba: "ABA",
      branch_number: "支店名",
      branch_code: "支店コード",
      bank_code: "銀行コード",
      phone: "携帯番号",
      iban: "IBAN",
      sort_code: "Sort Code",
      placeInner: "属性を入力してください",
      labelCurrency: "通貨＃ツウカ＃",
      placeCurrency: "通貨を選択してください",
      labelNumber: "数量",
      placeNumber: "数量を入力してください",
      messageNumber: "数量を入力してください",
      labelPrice: "価格",
      placePrice: "価格を入力してください",
      placeAmount: "金額を入力してください",
      labelJoinMoney: "加入金額",
      labelOldPassword: "古いパスワード",
      placeOldPassword: "古いパスワードを入力してください",
      messageOldPassword: "古いパスワードを入力してください",
      psdTip: "パスワードは6～16ビットアルファベット+デジタルの組み合わせでなければなりません",
      labelNewPassword: "新しいパスワード",
      placeNewPassword: "新しいパスワードを入力してください",
      messageNewPassword: "新しいパスワードを入力してください",
      labelCrmPassword: "パスワードの確認",
      placeCrmPassword: "パスワードを再入力してください",
      messageCrmPassword: "パスワードを再入力してください",
      labelCountry: "国/地域",
      placeCountry: "国を選択してください",
      messageCountry: "国を選択してください",
      labelId: "id番号",
      messageId: "ID番号を入力してください",
      labelCaptchaEmail: "認証コードがに送信されます",
      labelTotalAmount: "合計",
   },
   table: {
      labelName: "の名前をあげる",
      labelLastPrice: "最新価格",
      labelRang: "24 H上昇幅",
      labelVolume: "24 H出来高",
      labelVolumeNum: "24時間ボリューム",
      labelKline: "K線図",
      labelHeight: "24H 高い",
      labelLow: "24Hロー",
      labelOperate: "リグ",
      labelTime: "回",
      labelCurrency: "通貨タイプ",
      labelAmount: "金額",
      labelState: "情勢",
      state0: "完了",
      state1: "挫く",
      state2: "キャンセル",
      withdrawState0: "承認待ち",
      withdrawState1: "完了",
      withdrawState2: "不採用",
      labelDirection: "オリエンテーション",
      labelPrice: "物価",
      labelLever: "プライバー",
      labelHangNum: "せりばんご",
      gmtCreate: "作成時間",
      operate: "リグ",
      labelPeriod: "決済サイクル",
      every: "各",
      hour: "時間",
      day: "空",
      labelFundRate: "資金調達率",
      labelDailyRate: "即日資金調達率",
      labelLevesCurrency: "投資通貨",
      labelReturn: "年率換算収益率",
      labelLevesTerm: "投資期間",
      labelStartAmount: "開始金額",
      viewDetails: "詳細を見る",
      labelPairs: "トランザクションカウンターパート",
      labelType: "類型論",
      labelEntrustNumber: "コミッション数",
      labelTransNumber: "取引件数",
      labelTotalAmount: "取引総額",
      labelEntrustTime: "試運転時間",
      labelBuyPrice: "入札価格",
      labelBuyNumber: "買い付け数量",
      labelBuyTime: "購入時期",
      labelSellPrice: "売りレート",
      labelProfitLoss: "盛衰",
      labelNumber: "数量",
      labelMinerTitle: "財務名",
   },
   foot: {
      menuTit1: "会社概要",
      menuTit2: "お問い合わせ",
      menuTit3: "資源",
      menuTit4: "帮助",
      menuTit5: "取引",
      menuItem1: "コインストア・チーム",
      menuItem2: "認証チャネル",
      menuItem3: "ビジネス・コレクション認可",
      menuItem4: "アソシエイト",
      menuItem5: "コインストアについて",
      menuItem6: "利用規約",
      menuItem7: "公式Eメール",
      menuItem8: "コインストア契約",
      menuItem9: "コイン・アプリケーション",
      menuItem10: "現在の市場状況",
      menuItem11: "コインストア・アカデミー",
      menuItem12: "スタンダード料金",
      menuItem13: "出金手数料と出金限度額",
      menuItem14: "通貨の説明",
      menuItem15: "確実性",
      menuItem16: "入出金状況",
      menuItem17: "提案とフィードバック",
      menuItem18: "ヘルプセンター",
      menuItem19: "作業指示書の提出",
      menuItem20: "APIドキュメント",
   },
   market: {
      spots: {
         filterTab1: "セルフサービス",
         filterTab2: "コイン相場",
         filterTab3: "契約市場",
         filterTab4: "Launchpad",
         tabAll: "フル",
         tab1: "メインエリア",
         tab2: "新しい道を切り開く",
         tab3: "オープンエリア",
         tab4: "Prime",
         tab5: "小銭",
         tab6: "契約",
         tab7: "USDTフォーエバー",
         tab8: "フルカレンシー・パーペチュアル",
      }
   },
   trade:{
      chartMsg: "通貨情報",
      marketPrice:"市場価値",
      limitPrice:"価格制限",
      marketPriceTip: "成行注文は、その時の市場の最良価格を使って売買される。 ",
      lever: "プライバー",
      handNum: "せりばんご",
      margin: "証拠金",
      assets:"負債",
      balance:"バランス",
      book: "コミッションブック",
      quantity: "累計額",
      navState0: "ポジションオープン待ち",
      navState1: "現在のポジション",
      navState2: "歴史的なコミッション",
      navState3: "現在のコミッション",

      fund: {
         tit1: "契約市場",
         tit2: "ファンディング・レート履歴",
         p1: "投資家のポジションが自動的に縮小されるのを防ぐための保険基金。 このファンドは、未約定のクローズド・マンデートの価格を改善し、自動ポジション削減システムに引き継がれるのを防ぐために使用される。",
         p2: "保険資金の増加は、破綻価格よりも有利な価格で市場で取引される強力なフラット・マンデートによるものである。。",
         p3: "保険基金の現在および過去の残高は以下の通りである。。",
         tab1: "USDTフォーエバー",
         tab2: "フルカレンシー・パーペチュアル",
         contract: "契約",
      },
   },
   earn: {
      tit: "ワンクリック誓約書、日ごとに収入を増やす",
      tab1: "定期ファイナンス",
      orderTit: "財務オーダー",
      labelTotalApr: "年率換算収益率",
      labelTimeLimit: "プロジェクトサイクル",
      labelMoney: "受入額",
      labelType: "ペイバック方式",
      labelType1: "満期時の債務サービス",
      labelNowBalance: "現在の残高",
   },
   invite: {
      tit: "友人を招待する",
      p: "このQRコードをスキャンして友人を招待する",
      iviteLink: "招待リンク",
      registerCode: "登録コード",
   },
   noPermissions: {
      tip: "申し訳ありませんが、あなたのエリアは一時的にジャオ法防衛週このプラットフォーム駅、移動するサービスを提供するために黒法です！",
   },
   order: {
      tit: "注文記録",
      tab1: "オプション注文",
      tab2: "コイン注文",
      tab3: "契約注文",
      tab4: "財務オーダー",
      filterType0: "全種類",
      filterType1: "完了",
      filterType2: "キャンセル",
      filterType3: "現在のポジション",
      filterDirection0: "全方位",
      filterDirection1: "買い付け",
      filterDirection2: "付く",
   },
   personal: {
      tit1: "セキュリティレベル",
      tit2: "ログインパスワード」、",
      tit3: "取引パスワード",
      tit4: "セキュリティ設定",
      strong: "精力的",
      setting: "セットアップ",
      settings: "セットアップ",
      change: "バリエーション",
      idTip1: "認証する",
      idTip2: "残りの操作に進む前に認証を行う",
      withdrawTip1: "退会住所",
      withdrawTip2: "出金先の住所をご記入ください。",
      loginPsdTit: "ログインパスワードの設定",
      withdrawPsdTit: "出金用パスワードの設定",
      idTit: "実名認証",
      phoneTit: "携帯電話ボンディング",
      phoneTip: "電話変更後24時間はキャンセル不可",
      walletTit: "綴じ財布",
      accountNumber: "口座番号",
      addAddress: "アドレスの追加",

   },
   contest: {
      hot: "ホットなイベント",
   },
   city: {
      albania: "アルバニア",
      algeria: "アルジェリア",
      argentina: "アルゼンチン",
      armenia: "アルメニア",
      australia: "オーストラリア",
      pakistan: "パキスタン",
      austria: "オーストリア",
      bahrain: "オーストリア",
      belgium: "ベルギー",
      bosnia_and_Herzegovina: "ボスニア・ヘルツェゴビナ",
      brazil: "ブラジル",
      brunei: "ブルネイ",
      bulgaria: "ブルガリア",
      cambodia: "カンボジア",
      canada: "カナダ",
      cameroon: "カメルーン",
      chile: "チリ",
      colombia: "コロンビア",
      costa_Rica: "コスタリカ",
      croatia: "クロアチア",
      cyprus: "キプロス",
      czech_Republic: "チェコ共和国",
      denmark: "デンマーク",
      dominican_Republic: "ドミニカ共和国",
      egypt: "エジプト",
      estonia: "エストニア",
      ethiopia: "エチオピア",
      finland: "フィンランド",
      france: "フランス",
      georgia: "グルジア",
      germany: "ドイツ",
      ghana: "ガーナ",
      greece: "ギリシャ",
      guyana: "ガイアナ",
      honduras: "ホンジュラス",
      hong_Kong_China: "中国香港",
      hungary: "ハンガリー",
      iceland: "アイスランド",
      ireland: "アイルランド",
      italy: "イタリア",
      india: "インド",
      indonesia: "インドネシア",
      israel: "イスラエル",
      iran: "イラン",
      iraq: "イラク",
      japan: "日本",
      kazakstan: "カザフスタン",
      kenya: "ケニア",
      korea: "韓国",
      kuwait: "クウェート",
      kyrgyzstan: "キルギス",
      laos: "ラオス",
      latvia: "ラトビア",
      lithuania: "リトアニア",
      luxembourg: "ルクセンブルク",
      macao_China: "中国マカオ",
      macedonia: "マケドニア",
      malaysia: "マレーシア",
      malta: "マルタ",
      mexico: "メキシコ",
      moldova: "モルドバ",
      monaco: "モナコ",
      mongolia: "モンゴル",
      montenegro: "モンテネグロ共和国",
      morocco: "モロッコ",
      myanmar: "ミャンマー",
      netherlands: "オランダ",
      new_Zealand: "ニュージーランド",
      nepal: "ネパール",
      nigeria: "ナイジェリア",
      norway: "ノルウェー",
      oman: "オマーン",
      palestine: "パレスチナ",
      panama: "パナマ",
      paraguay: "パラグアイ",
      peru: "ペルー",
      philippines: "フィリピン",
      poland: "ポーランド",
      portugal: "ポルトガル",
      puerto_Rico: "プエルトリコ",
      qatar: "カタール",
      romania: "ルーマニア",
      russia: "ロシア",
      republic_of_Trinidad_and_Tobago: "トリニダード・トバゴ共和国",
      rwanda: "ルワンダ",
      saudi_Arabia: "サウジアラビア",
      serbia: "セルビア",
      singapore: "シンガポール",
      slovakia: "スロバキア",
      slovenia: "スロベニア",
      south_Africa: "南アフリカ共和国",
      spain: "スペイン",
      sri_Lanka: "スリランカ",
      sweden: "スウェーデン",
      switzerland: "スイス",
      taiwan_China: "中国台湾",
      tajikistan: "タジキスタン",
      tanzania: "タンザニア",
      thailand: "タイ",
      turkey: "トルコ",
      turkmenistan: "トルクメニスタン",
      ukraine: "ウクライナ",
      united_Arab_Emirates: "アラブ首長国連邦",
      united_Kingdom: "英国",
      united_States: "アメリカ",
      uzbekistan: "ウズベキスタン",
      venezuela: "ベネズエラ",
      vietnam: "ベトナム",
      afghanistan: "アフガニスタン",
      angola: "アンゴラ",
      azerbaijan: "アゼルバイジャン",
      bangladesh: "バングラデシュ",
      belarus: "ベラルーシ",
      belize: "ベリーズ",
      benin: "ベニン",
      bhutan: "ブータン",
      bolivia: "ボリビア",
      botswana: "ボツワナ",
      british_Virgin_Islands: "イギリス領ヴァージン諸島",
      burkina_Faso: "ブルキナファソ",
      burundi: "ブルンジ",
      cape_Verde: "カーボベルデ角",
      cayman_Islands: "ケイマン諸島",
      central_African_Republic: "中央アフリカ共和国",
      chad: "チャド",
      comoros: "コモロ",
      the_Republic_of_the_Congo: "コンゴ（布）",
      democratic_Republic_of_the_Congo: "コンゴ（金）",
      djibouti: "ジブチ",
      ecuador: "エクアドル",
      el_Salvador: "エルサルバドル",
      equatorial_Guinea: "赤道ギニア",
      eritrea: "エリトリア",
      fiji: "フィジー",
      gabon: "ガボン",
      gambia: "ガンビア",
      greenland: "グリーンランド",
      guatemala: "グアテマラ",
      guinea: "ギニア",
      haiti: "ハイチ",
      isle_of_Man: "マーン島",
      cote_d_Ivoire: "コートジボワール",
      jamaica: "ジャマイカ",
      jordan: "ヨルダン",
      lebanon: "レバノン",
      lesotho: "レソト",
      liberia: "リベリア",
      libya: "リビア",
      madagascar: "マダガスカル",
      malawi: "マラウイ",
      maldives: "モルディブ",
      mali: "マリ",
      mauritania: "モーリタニア",
      mauritius: "モーリシャス",
      mozambique: "モザンビーク",
      namibia: "ナミビア",
      nicaragua: "ニカラグア",
      republic_of_Niger: "ニジェール",
      north_Korea: "朝鲜",
      reunion: "レユニオン",
      san_Marino: "サンマリノ",
      senegal: "セネガル",
      sierra_Leone: "シエラレオネ",
      somalia: "ソマリア",
      sudan: "スーダン",
      suriname: "スリナム",
      eswatini: "スワジランド",
      syria: "シリア",
      togo: "トーゴ",
      tonga: "トンガ",
      tunisia: "チュニジア",
      united_States_Virgin_Islands: "アメリカ領ヴァージン諸島",
      uganda: "ウガンダ",
      uruguay: "ウルグアイ",
      vatican: "バチカン",
      yemen: "イエメン",
      yugoslavia: "ユーゴスラビア",
      zambia: "ザンビア",
      zimbabwe: "ジンバブエ",
      china: "中国",
   }
};






