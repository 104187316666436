<template>
  <router-view></router-view>
</template>
<script>
import axios from 'axios';
export default {
  mounted() {
    // if (this._isMobile()) {
    //   window.location.href = "https://exchange.ICESAS.run/";
    // }
    this.getIPAddress()
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    getIPAddress() {
      const url = 'http://api.ipify.org?format=json';
      let ip = "";
      axios.get(url).then(response => {
        ip = response.data.ip;
        console.log(ip);
        // let formData = {
        //   ip: "223.70.131.150"   //这里做测试ip地址写死的
        // };
        // axios({
        //   url: "/cityApi",
        //   method: 'get',
        //   params: formData
        // }).then(res => {
        //   console.log("res:", res, JSON.parse(JSON.stringify(res.data)))
        //   let result1 = JSON.parse(JSON.stringify(res.data))
        //   let data1 = result1.split(';')[1]
        //   let data2 = data1.split('=')[1]
        //   let data3 = data2.slice(1)
        //   let data4 = data3.split('}')[0]
        //   let data5 = data4.split(',')
        //   console.log("data1:", data1)
        //   console.log("data2:", data2)
        //   console.log("data3:", data3)
        //   console.log("data4:", data4)
        //   console.log("data5:", data5)
        // })
      });
    },
  }
}
</script>
<style lang="less">
body {
  --color-theme-green: #2ebd85;
  --color-theme-red: #fe535b;
  --bg-theme-red: rgba(254, 83, 91, .2);
  --bg-theme-green: rgba(56, 173, 123, .2);

  --fontFamily: DINPro-Medium;
  --font-number: DINPro-Medium;
  --font-number-blod: DINPro-Bold;
  --font-footer: DINPro-Medium;
  --font-family-pm: DINPro-Medium;
  --font-weight-blod: DINPro-Bold;
  --font-weight: 500;
  --font-Regular: DINPro-Medium;
}

&::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3C3F434D;
  // background: transparent;
}

*::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: var(#3C3F434D);
  background: #3C3F434D;
}

*::-webkit-scrollbar-track {
  background: var(--el-color-white);
  background: #FFF;
}

.el-input__inner:focus {
  border: #DCDFE6;
  border: 1px solid #DCDFE6 !important;
}

.popper__arrow {
  border: none !important;

  &::after {
    border: none !important;
  }
}
</style>
