import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/web',
    name: 'web',
    component: () => import('@/views/home/web'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login'),
  },
  {
    path: '/register',
    name: 'login',
    component: () => import('@/views/login/register'),
  },
  {
    path: '/forget',
    name: 'login',
    component: () => import('@/views/login/forget'),
  },
  {
    path: '/noPermissions',
    name: 'noPermissions',
    component: () => import('@/views/noPermissions/noPermissions'),
  },
  {
    path: '/market/spots',
    name: 'market',
    component: () => import('@/views/market/spots'),
  },
  {
    path: '/seconds',
    name: 'seconds',
    component: () => import('@/views/seconds/seconds'),
  },
  {
    path: '/spot',
    name: 'spot',
    component: () => import('@/views/spot/spot'),
  },
  {
    path: '/contract',
    name: 'contract',
    component: () => import('@/views/contract/contract'),
  },
  {
    path: '/contract/market',
    name: 'contract',
    component: () => import('@/views/contract/market'),
  },
  {
    path: '/contract/fund',
    name: 'contract',
    component: () => import('@/views/contract/fund'),
  },
  {
    path: '/earn/list',
    name: 'earn',
    component: () => import('@/views/earn/list'),
  },
  {
    path: '/earn/order',
    name: 'earn',
    component: () => import('@/views/earn/order'),
  },
  {
    path: '/trading_contest/list',
    name: 'trading_contest',
    component: () => import('@/views/trading_contest/list'),
  },
  {
    path: '/trading_contest/details',
    name: 'trading_contest',
    component: () => import('@/views/trading_contest/details'),
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import('@/views/personal/personal'),
  },
  {
    path: '/assets',
    name: 'assets',
    component: () => import('@/views/assets/assets'),
  },
  {
    path: '/assets/recharge',
    name: 'assets',
    component: () => import('@/views/assets/recharge'),
  },
  {
    path: '/assets/withdraw',
    name: 'assets',
    component: () => import('@/views/assets/withdraw'),
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/order/order'),
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import('@/views/invite/invite'),
  },



]
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}


const router = new VueRouter({
  routes
})

export default router
