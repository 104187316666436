import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

//导入语言包
import Zh from '../lang/zh.js'; // 中文语言包
import En from '../lang/en.js'; // 英文语言包
import Jp from '../lang/jp.js'; // 日语语言包
import Ko from '../lang/ko.js'; // 韩语语言包
import De from '../lang/de.js'; // 德语语言包
import It from '../lang/it.js'; // 意大利语语言包
import Fr from '../lang/fr.js'; // 法语语言包
import Es from '../lang/es.js'; // 西班牙语语言包
import Pt from '../lang/pt.js'; // 葡萄牙语语言包



const messages = {
    zh: Zh,
    en: En, // 英文语言包
    jp: Jp, // 日语语言包
    ko: Ko, // 韩语语言包
    de: De, // 德语语言包
    it: It, // 意大利语语言包
    fr: Fr, // 法语语言包
    es: Es, // 西班牙语语言包
    pt: Pt, // 葡萄牙语语言包
}
window.localStorage.setItem("lang", 'en')
// window.sessionStorage.setItem("lang", 'zh')
export default new VueI18n({
    locale: 'en', // set locale 默认显示英文
    fallbackLocale: 'en', //如果语言包没有，则默认从中文中抽取
    messages: messages // set locale messages
});
